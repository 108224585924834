<template>
  <div>
    <div class="air__utils__heading" v-show="typeTable == 'catalogs'">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes" v-show="typeTable == 'catalogs'"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center" v-if="!block">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="client" label="Nombre del Cliente" prop="client" >
            <a-select v-model="form.client_id"
              placeholder="Seleccionar el Cliente"
              show-search
              :filter-option="filterOption"
              :disabled="clientId !== null"
            >
            <!-- Todo bloquear cuando el prop clientId no sea nulo -->
              <a-select-option  v-for="option in clients" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="name" label="Nombre de la Planta del Cliente" prop="name" >
            <a-input v-model.trim="form.name"/>
          </a-form-model-item>
          <a-form-model-item ref="state" label="Estado" prop="state" >
            <a-select v-model="form.state_id"
              placeholder="Seleccionar Estado"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in states" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="municipality" label="Municipio" prop="municipality" >
            <a-input v-model.trim="form.municipality"/>
          </a-form-model-item>
          <a-form-model-item ref="zip_code" label="Código Postal" prop="zip_code" >
            <a-input v-model.trim="form.zip_code"/>
          </a-form-model-item>
          <a-form-model-item ref="address" label="Dirección" prop="address" >
            <a-textarea v-model.trim="form.address" :rows="4" allowClear />
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!load">
            <template v-if="!block">
              <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
              <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"/>
            </template>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject" :typeTable="typeTable"
                          @Modal="OpenModal" @Filter="getData" @Select="Selected"
                          @searchInput="searchInput = $event"
    >
      <div slot="details" slot-scope="record">
        <small><strong><b-icon icon="person-check-fill"></b-icon> Cliente: </strong>{{record.record.clients.name}}<br></small>
        <br>
        <small><strong><b-icon icon="building"></b-icon> Estado: </strong>{{record.record.states.name}}<br></small>
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
export default {
  name: 'workCenterClientForm',
  props: {
    typeTable: {
      default: 'catalogs',
      type: String,
    },
    clientId: {
      default: null,
      type: String,
      required: false,
    },
    block: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  data() {
    return {
      // <!-- Constantes -->
      resource: 'workCenterClients',
      resourceName: 'Plantas del Cliente',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Plantas del Cliente',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Nombre de la Planta del Cliente',
            dataIndex: 'name',
            key: 'name',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Municipio',
            dataIndex: 'municipality',
            key: 'municipality',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.municipality.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Código Postal',
            dataIndex: 'zip_code',
            key: 'zip_code',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.zip_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.address.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Detalles',
            dataIndex: 'detail',
            key: 'detail',
            class: 'small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            // fixed: 'right',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'name', text: 'Nombre del Cliente' },
        { value: 'municipality', text: 'Municipio' },
        { value: 'zip_code', text: 'Código Postal' },
        { value: 'address', text: 'Dirección' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        client_id: undefined,
        name: '',
        state_id: undefined,
        municipality: '',
        zip_code: '',
        address: '',
      },
      // Relaciones
      relationships: {
        states: {
          data: { id: 0, type: 'states' },
        },
        clients: {
          data: { id: 0, type: 'clients' },
        },
      },
      // arrays selects
      clients: [],
      states: [],
      // Reglas de validación
      rules: {
        client_id: [
          { required: true, message: 'El campo cliente es obligatorio.', trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'El campo Nombre de Cliente es obligatorio.', trigger: 'blur' },
          { min: 3, max: 400, message: 'El campo Nombre debe contener entre 3 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Nombre debe ser de tipo texto.', trigger: 'blur' },
        ],
        state_id: [
          { required: true, message: 'El campo Estado es obligatorio.', trigger: 'blur' },
        ],
        municipality: [
          { required: true, message: 'El campo Municipio es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Municipio debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Municipio debe ser de tipo texto.', trigger: 'blur' },
        ],
        zip_code: [
          { required: true, message: 'El campo Código Postal es obligatorio.', trigger: 'blur' },
          { min: 2, max: 10, message: 'El campo Código Postal debe contener entre 2 y 10 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Código Postal debe ser de tipo numérico.', trigger: 'blur' },
        ],
        address: [
          { required: true, message: 'El campo Dirección es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Dirección debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Dirección debe ser de tipo texto.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  watch: {
    clientId(newValue) {
      this.getData()
      // this.clientId = newValue
    },
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      // Todo agregar logica para cuando el prop clientId no es nulo (buscar solo cliente especifico)
      let params = {}
      if (this.clientId !== null) {
        params = {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[search]': `${this.searchValue}`,
          'filter[clients]': this.clientId,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'states,clients',
        }
      } else {
        params = {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[global]': `${this.searchValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'states,clients',
        }
      }

      this.$store.dispatch('jv/get', [this.resource, {
        params: params,
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getClients() {
      let params = {}
      if (this.clientId !== null) {
        params = {
          'filter[code]': this.clientId,
        }
      } else {
        params = {
          sort: 'name',
        }
      }
      // Todo agregar logica para cuando el prop clientId no es nulo (buscar solo cliente especifico)
      this.$store.dispatch('jv/get', ['clients', {
        params: params,
      }])
        .then(response => {
          const tableData = response
          this.clients = objectArray(tableData)
        })
    },
    getStates() {
      this.$store.dispatch('jv/get', ['states', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.states = objectArray(tableData)
        })
    },
    getPlants() {
      this.$store.dispatch('jv/get', ['workCenters', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.Plants = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getClients()
      this.getStates()
      if (Object.keys(data).length === 0) {
        //  Store case
        // Todo agregar logica para cuando el prop clientId no es nulo (agregar client_id automaticamente)
        this.id = '0'
        this.form.name = ''
        this.form.municipality = ''
        this.form.zip_code = ''
        this.form.address = ''
        this.form.state_id = undefined
        if (this.clientId !== null) {
          this.form.client_id = this.clientId
        } else {
          this.form.client_id = undefined
        }
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.name = data.name
        this.form.municipality = data.municipality
        this.form.zip_code = data.zip_code
        this.form.address = data.address
        this.form.client_id = data.clients.jsonApi.id
        this.form.state_id = data.states.jsonApi.id
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.clients.data.id = this.form.client_id
          this.relationships.states.data.id = this.form.state_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              name: `${this.form.name}`,
              municipality: `${this.form.municipality}`,
              zip_code: `${this.form.zip_code}`,
              address: `${this.form.address}`,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              name: this.form.name,
              municipality: `${this.form.municipality}`,
              zip_code: `${this.form.zip_code}`,
              address: `${this.form.address}`,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        clients: {
          data: { id: 0, type: 'clients' },
        },
        states: {
          data: { id: 0, type: 'states' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
    Selected(data) {
      this.$emit('Select', data)
    },
  },
  mounted() {
    this.getData()
    this.getPlants()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
