<template>
  <div>
    <div class="air__utils__heading" v-show="catalogType == 'catalog'">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes" v-show="catalogType == 'catalog'"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <!--Botonera  -->
    <div class="row justify-content-center mb-2">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="clients" label="Cliente" prop="clients" >
            <a-select v-model="form.client_id"
              placeholder="Seleccionar cliente"
              show-search :filter-option="filterOption"
              >
              <a-select-option  v-for="option in clients" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="name" label="Nombre del contacto" prop="name" >
            <a-input v-model.trim="form.name"/>
          </a-form-model-item>
          <a-form-model-item ref="businesPosition" label="Puesto del Contacto" prop="businesPosition" >
            <a-input v-model.trim="form.business_position "/>
          </a-form-model-item>
          <a-form-model-item ref="email" label="Correo Electrónico" prop="email" >
            <a-input v-model.trim="form.email"/>
          </a-form-model-item>
          <a-form-model-item ref="phone" label="Teléfono (XX-XXXX-XXXX)" prop="phone" >
            <a-input v-model.trim="form.phone"/>
          </a-form-model-item>
          <a-form-model-item ref="address" label="Dirección" prop="address" >
            <a-input v-model.trim="form.address"/>
          </a-form-model-item>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            <responsive-button v-if="this.id !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete"  v-show="catalogType == 'catalog'"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
      :typeTable="typeTable"
      @Modal="OpenModal"
      @Select="Select"
      @Filter="getData"
      @searchInput="searchInput = $event"
    >
      <div slot="details" slot-scope="record">
        <small>
          <strong>{{record.record.clients.sap_code}}</strong> - <strong>{{record.record.clients.name}}</strong>
        </small>
      </div>
    </detail-catalog-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogTable from '../components/table'
import DetailCatalogBar from '../components/searchBar'
import { notification, Modal } from 'ant-design-vue'
export default {
  name: 'contactForm',
  components: {
    DetailCatalogTable,
    DetailCatalogBar,
  },
  props: {
    catalogType: {
      type: String,
      default: 'catalog',
    },
    clientId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      typeTable: 'catalogs',
      // <!-- Constantes -->
      resource: 'contacts',
      resourceName: 'Contactos',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'catalogs',
          breadcrumbName: 'Catálogos',
          aIcon: '',
        },
        {
          breadcrumbName: 'Contactos',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Nombre del contacto',
            dataIndex: 'name',
            key: 'name',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Puesto del Contacto',
            dataIndex: 'business_position',
            key: 'business_position',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.business_position.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Correo Electrónico',
            dataIndex: 'email',
            key: 'email',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Teléfono',
            dataIndex: 'phone',
            key: 'phone',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Cliente',
            dataIndex: 'detail',
            key: 'detail',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'name', text: 'Nombre del Contacto' },
        { value: 'business_position', text: 'Dirección' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      id: '0',
      form: {
        client_id: undefined,
        name: '',
        business_position: '',
        email: '',
        phone: '',
        address: '',
      },
      // Relaciones
      relationships: {
        clients: {
          data: { id: 0, type: 'clients' },
        },
      },
      // arrays selects
      clients: [],
      // Reglas de validación
      rules: {
        client_id: [
          { required: true, message: 'El campo País es obligatorio.', trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'El campo Nombre es obligatorio.', trigger: 'blur' },
          { min: 2, max: 400, message: 'El campo Registro Ambiental debe contener entre 2 y 400 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Registro Ambiental debe ser de tipo texto.', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'El campo Correo es obligatorio.', trigger: 'blur' },
          { min: 2, max: 100, message: 'El campo Correo debe contener entre 2 y 100 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Correo debe ser de tipo texto.', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: 'El campo Número Telefónico es obligatorio.', trigger: 'blur' },
          { min: 2, max: 20, message: 'El campo Número Telefónico debe contener entre 2 y 20 caracteres.', trigger: 'blur' },
          { type: 'string', message: 'El campo Número Telefónico debe ser de tipo numérico.', trigger: 'blur' },
          { type: 'regexp', regexp: '/^[0-9]{2}-[0-9]{4}-[0-9]{4}$/', message: 'El campo teléfono debe tener el formato XX - XXXX-XXXX' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  computed: {
    ...mapState(['servicePlannings']),
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    contactInfo() {
      return this.$store.state.servicePlannings.contactInfo
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    ...mapActions('servicePlannings'),
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true

      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
        'page[size]': `${this.perPageValue}`,
        sort: `${this.sortDirection}${this.sortValue}`,
        include: 'clients',
      }
      if (this.clientId !== null) params['filter[clients]'] = this.clientId
      if (this.searchValue) {
        console.log(this.searchValue)
        params['filter[global]'] = this.searchValue
      }

      this.$store.dispatch('jv/get', [this.resource, {
        params: params,
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    getClients() {
      const params = {
        sort: 'name',
      }

      if (this.clientId !== null) {
        params['filter[code]'] = this.clientId
      }
      this.$store.dispatch('jv/get', ['clients', {
        params: params,
      }])
        .then(response => {
          const tableData = response
          this.clients = objectArray(tableData)
        })
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    Select(data) {
      this.$store.dispatch('servicePlannings/UPDATE_CONTACT_INFO', { payload: data })
    },
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getClients()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.id = '0'
        this.form.name = ''
        this.form.business_position = ''
        this.form.email = ''
        this.form.phone = ''
        this.form.address = ''
      } else {
        // Update case
        this.id = data.jsonApi.id
        this.form.name = data.name
        this.form.client_id = data.clientRelationKey
        this.form.business_position = data.business_position
        this.form.email = data.email
        this.form.phone = data.phone
        this.form.address = data.address
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.id = '0'
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.clients.data.id = this.form.client_id
          if (this.id === '0') {
            // Store Object
            formObject = {
              name: `${this.form.name}`,
              business_position: `${this.form.business_position}`,
              email: `${this.form.email}`,
              phone: `${this.form.phone}`,
              address: `${this.form.address}`,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              name: `${this.form.name}`,
              business_position: `${this.form.business_position}`,
              email: `${this.form.email}`,
              phone: `${this.form.phone}`,
              address: `${this.form.address}`,
              jsonApi: {
                type: this.resource,
                id: this.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.getData()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetRelathionships() {
      this.relationships = {
        clients: {
          data: { id: 0, type: 'clients' },
        },
      }
    },
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
    if (this.catalogType === 'select') {
      this.typeTable = 'select'
    } else {
      this.typeTable = 'catalogs'
    }
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
